import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Excited: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="14" height="19" viewBox="0 0 14 19">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path
                    stroke={color}
                    strokeWidth="1.25"
                    d="M3.125 9.375v2.5H0l6.875 6.875 6.875-6.875h-3.125v-2.5m-7.5-2.5v-2.5m0-2.5V0m7.5 6.875v-2.5m0-2.5V0"
                />
            </g>
        </svg>
    );
};

export default Excited;
